<template>
  <div>
    <van-nav-bar title="我推荐的公众号门店" left-arrow :fixed="true" :z-index="999" @click-left="back" />

    <div class="ma">
      <vue-qr
        :text="downloadData.url"
        :margin="10"
        colorDark="#000"
        colorLight="#fff"
        :dotScale="1"
        :logoSrc="downloadData.icon"
        :logoScale="0.2"
        :size="220"
      ></vue-qr>
    </div>
  </div>
</template>

<script>
import userwei from "../../api/user";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr
  },
  data() {
    return {
      list: [],
      downloadData: {
        url: "",
        icon: ""
      },
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      nameList: {}
    };
  },
  methods: {
    onLoad() {
      // userwei.tuokema({ customerId: this.userInfo.id,gid:this.shangjia.id }).then(res => {
      //   this.downloadData.url = res.data.url;
      // });
      this.downloadData.url =
        "https://shopping.car-posthouse.cn/tuoke_initiation?gid=" +
        this.shangjia.id +
        "&garageName=" +
        this.shangjia.name, +
        "&customerId=" +
        this.userInfo.id;
    },
    // creatQrCode() {
    //   // var utf = this.nameList.garageName

    //   //    var garageNameutf = encodeURIComponent(utf);
    //   var url = "https://shopping.car-posthouse.cn/tuoke_initiation?gid=" + this.nameList.gid + '&garageName=' + this.nameList.garageName + '&customerId=' + this.nameList.customerId
    //       // console.log(url)

    //   // console.log(decodeURIComponent(utf))
    //   // console.log(utf)
    //   var id = 25;
    //   var qrcode = new QRCode(this.$refs.qrCodeUrl, {
    //     text: url, // 需要转换为二维码的内容
    //     width: 200,
    //     height: 200,
    //     colorDark: "#000000",
    //     colorLight: "#ffffff",
    //     correctLevel: QRCode.CorrectLevel.H
    //   });
    // },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.nameList = this.$route.params;
    // console.log(this.$route.params);
    this.onLoad();
  },
  mounted() {
    // this.creatQrCode();
  }
};
</script>

<style lang="less" scoped>
.ma {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
}
</style>
